import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, withRouter, Route } from 'react-router-dom';
import { Auth, Logger } from 'aws-amplify';
import { isEqual } from 'lodash';
import {
  Divider,
  Dropdown,
  Icon,
  Layout,
  Menu,
  notification,
} from 'antd';

import { withTranslation } from 'react-i18next';

import './app.less';

import Context from './context';
import MenuItems, {
  getFlatMenuItems,
  isMenuItemVisibleForUser,
  hasVisibleSubMenuItemForUser,
} from './constants/menus';
import ErrorBoundary from './components/ErrorBoundary';
import Routes from './routes';
import Logo from './components/Logo';
import TransmissionNetworkStatus from './components/TransmissionNetworkStatus';
import Websocket from './websocket';
import { isEdge } from './utils/detectBrowser';
import { parseNotification } from './utils/notifications';
import Loading from './components/Loading';
import SelectMarketParty from './components/SelectMarketParty';
import Feedback from './components/Feedback';
import Survey from './components/Survey';

const { Header, Sider } = Layout;

const log = new Logger('app');

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      websocket: new Websocket(this.onWebsocketMessage),
    };
  }

  componentDidMount = () => {
    if (this.isSignedIn()) {
      this.initialize();
    }
  }

  componentDidUpdate = (prevProps) => {
    const prevAuthState = prevProps.authState;
    if (prevAuthState !== 'authenticated' && this.isSignedIn()) {
      this.initialize();
    }
  }

  initialize = async () => {
    const { initialize } = this.context;
    const { websocket } = this.state;
    initialize();
    await websocket.connect();
  }

  isSignedIn = () => {
    const { authState } = this.props;
    return authState === 'authenticated';
  }

  getUserMenu = () => {
    const { t } = this.props;
    return (
      <Menu className="layout__header__user__menu">
        <Menu.Item>
          <Link to="/apidocs">{t('app.menu.apiDocs')}</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/settings">{t('app.menu.settings')}</Link>
        </Menu.Item>
        {/* TODO: Remove React fragment after bug in Divider has been fixed in antd */}
        <>
          <Divider className="layout__header__user__menu__divider" />
        </>
        <Menu.Item>
          <a onClick={this.handleLogOut} href="/logout">{t('app.menu.logOut')}</a>
        </Menu.Item>
      </Menu>
    );
  }

  handleLogOut = async (event) => {
    event.preventDefault();
    const { websocket } = this.state;
    const { clearCurrentUser } = this.context;
    const {
      history,
      t,
    } = this.props;

    try {
      await Auth.signOut();
      websocket.close();
      clearCurrentUser();
      history.push('/');
    } catch (e) {
      notification.error({
        className: 'notification-error',
        message: t('common.notifications.errorClosingConnection'),
        description: e.toString(),
      });
    }
  }

  handleNotificationActionClick = (url) => {
    const { history } = this.props;
    history.push(url);
  }

  onWebsocketMessage = (event) => {
    const {
      type,
      data,
    } = JSON.parse(event.data);

    switch (type) {
      case 'db': {
        const {
          type: target,
          action,
          item,
        } = data;

        const { handleDbChange } = this.context;
        handleDbChange(target, action, item);
        break;
      }

      case 'notification': {
        const {
          subject,
          message: msg,
          options = {},
        } = parseNotification(data, this.handleNotificationActionClick);

        // Display notification to user
        notification.info({
          message: subject,
          description: msg,
          ...options,
        });
        break;
      }

      default: {
        log.warn('Unhandled websocket message type:', type, data);
      }
    }
  }

  renderItemLink = (item) => {
    const { t } = this.props;

    return (
      <>
        <Link to={item.url}>
          {
            item.icon && item.icon !== ''
              ? (
                <Icon
                  className="layout__container__sidebar__menu__item__icon"
                  type={item.icon}
                />
              )
              : null
          }
          <p className="layout__container__sidebar__menu__item__text">{t(`app.menu.${item.name}`)}</p>
        </Link>
      </>
    );
  }

  renderSubMenuItem = item => (
    isMenuItemVisibleForUser(item, this.context)
    || hasVisibleSubMenuItemForUser(item, this.context)
      ? (
        <Menu.SubMenu
          className="layout__container__sidebar__submenu__item"
          key={item.url}
          title={this.renderItemLink(item)}
        >
          { this.renderMenuItems(item.submenus) }
        </Menu.SubMenu>
      )
      : null
  );

  renderMenuItem = item => (
    isMenuItemVisibleForUser(item, this.context)
      ? (
        <Menu.Item
          className="layout__container__sidebar__menu__item"
          key={item.url}
        >
          { this.renderItemLink(item) }
        </Menu.Item>
      )
      : null
  );

  renderMenuItems = items => items.map(item => (
    item.submenus && item.submenus.length
      ? this.renderSubMenuItem(item)
      : this.renderMenuItem(item)
  ));

  findOpenKey = () => {
    const {
      location: {
        pathname,
      },
    } = this.props;
    const keyItem = getFlatMenuItems().find(item => item.url === pathname);
    // If submenu otherwise url
    return keyItem && keyItem.parentUrl ? keyItem.parentUrl : pathname;
  }

  marketPartySelectionChanged = (marketPartyId) => {
    const { selectMarketParty } = this.context;

    selectMarketParty(marketPartyId);
  }

  getMarketPartyMenu = () => {
    const { t, history } = this.props;
    const {
      currentUser,
      getSelectedMarketParty,
    } = this.context;


    const selectedMarketParty = getSelectedMarketParty ? getSelectedMarketParty() : undefined;

    return currentUser !== undefined
      ? (
        <div className="layout__header__logo__market-party">
          <Menu className="layout__header__logo__market-party__menu">
            {
              (currentUser.inAdminGroup || currentUser.inReadOnlyAdminGroup) && ([
                <Menu.Item
                  key="admin"
                  className="menu-item-admin"
                  onClick={() => this.marketPartySelectionChanged('admin')}
                >
                  {t('app.menu.admin')}
                </Menu.Item>,
                selectedMarketParty && (
                  <Menu.Item
                    key="copy-marketpartyid"
                    className="menu-item-copy-marketpartyid"
                    onClick={() => (navigator.clipboard.writeText(selectedMarketParty.id))}
                  >
                    {t('app.menu.copyMarketPartyId')}
                  </Menu.Item>
                ),
                <Menu.Item
                  key="select-marketparty"
                  className="menu-item-select-marketparty"
                  onClick={() => history.push('select-marketparty')}
                >
                  {t('app.menu.selectMarketParty')}
                </Menu.Item>,
              ])
            }
            {
              (currentUser.ownMarketParties || []).map(marketParty => (
                <Menu.Item
                  key={`${marketParty.name}`}
                  className="menu-item-marketparty"
                  onClick={() => this.marketPartySelectionChanged(marketParty.id)}
                >
                  <span className="code">{marketParty.code}</span>
                  {marketParty.name}
                </Menu.Item>
              ))
            }
          </Menu>
        </div>
      )
      : null;
  }

  getMarketPartyName = () => {
    const {
      isAdmin,
      isReadOnlyAdmin,
      isAdminAsMarketParty,
      isReadOnlyAdminAsMarketParty,
      getSelectedMarketParty,
    } = this.context;
    const { t } = this.props;

    if (getSelectedMarketParty === undefined
      || getSelectedMarketParty() === undefined) {
      return '';
    }

    const marketParty = getSelectedMarketParty();

    let roleName = '';

    if (isReadOnlyAdmin() || isReadOnlyAdminAsMarketParty()) {
      roleName = t('app.menu.adminReadOnly');
    }
    if (isAdmin() || isAdminAsMarketParty()) {
      roleName = t('app.menu.admin');
    }

    if (isAdmin() || isReadOnlyAdmin()) {
      return roleName;
    }

    const name = marketParty.name || '';
    return (isAdminAsMarketParty() || isReadOnlyAdminAsMarketParty())
      ? (
        <>
          <span className="code">{marketParty.code}</span>
          &nbsp;
          {`${name} (${roleName})`}
        </>
      )
      : (
        <>
          <span className="code">{marketParty.code}</span>
          {name}
        </>
      );
  }

  renderLayout = () => {
    const initialUserData = {
      family_name: '',
      given_name: '',
      ownMarketParties: [],
      inAdminGroup: false,
      inReadOnlyAdminGroup: false,
    };
    const { location, t } = this.props;
    const { currentUser = initialUserData, selectedMarketPartyId } = this.context;
    const { inAdminGroup, inReadOnlyAdminGroup } = currentUser;
    const companyDropdownOverlay = this.getMarketPartyMenu();
    const isAdminAsMarketParty = inAdminGroup && selectedMarketPartyId && selectedMarketPartyId !== 'admin';
    const isReadOnlyAdminAsMarketParty = inReadOnlyAdminGroup && selectedMarketPartyId && selectedMarketPartyId !== 'admin';

    const isDanger = isAdminAsMarketParty || isReadOnlyAdminAsMarketParty;
    const headerClassName = classNames('layout__header', {
      'layout__header--danger': isDanger,
    });

    return (
      <Layout className="layout">
        <Header className={headerClassName}>
          <div className="layout__header__logo">
            <Logo dark={false} />
            <Dropdown
              className="layout__header__company__selector"
              overlay={companyDropdownOverlay}
            >
              <span className="layout__header__user__name">
                { this.getMarketPartyName() }
                &nbsp;
                <Icon type="down" />
              </span>
            </Dropdown>
          </div>
          { false && (
            <div className="layout__header__feedback-link">
              { <Survey /> }
            </div>
          )}
          <div className="layout__header__right-wrapper">
            { this.renderStatus()}
            { this.renderUser() }
          </div>
        </Header>
        { isEqual(currentUser, initialUserData)
          ? <Loading />
          : (
            <Layout className="layout__container">
              <Sider
                className="layout__container__sidebar"
                width={246}
                breakpoint="md"
                collapsedWidth="0"
              >
                <Menu
                  className={classNames('layout__container__sidebar__menu', { hideScrollbar: isEdge })}
                  mode="inline"
                  selectedKeys={[location.pathname]}
                  openKeys={[this.findOpenKey()]}
                  theme="dark"
                >
                  {this.renderMenuItems(MenuItems)}
                </Menu>
                <div className="external-links">
                  <a
                    href="https://gasgrid.fi/wp-content/uploads/Portal-Guidelines.docx"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('app.handbook')}
                  </a>
                  <br />
                  <a
                    href="https://gasgrid.fi/wp-content/uploads/Portaalin-paasyoikeusehdot_1.0.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('app.termsOfUse')}
                  </a>
                  <br />
                  { false && <Feedback /> }
                </div>
              </Sider>
              <Layout className="layout__container__page">
                <Routes
                  className="layout__container__page__routes"
                />
              </Layout>
            </Layout>
          )
        }
        {
          (currentUser.inAdminGroup || currentUser.inReadOnlyAdminGroup) && (
            <Route
              path="/select-marketparty"
              component={SelectMarketParty}
            />
          )
        }
      </Layout>
    );
  }

  renderUser = () => {
    const { currentUser } = this.context;

    return currentUser
      ? (
        <div className="layout__header__user">
          <Dropdown overlay={this.getUserMenu()}>
            <span className="layout__header__user__name">
              <Icon type="user" className="layout__header__user__name__icon" />
              <span className="layout__header__user__name__text">
                {`${currentUser.given_name} ${currentUser.family_name}`}
              </span>
            </span>
          </Dropdown>
        </div>
      )
      : null;
  }

  renderStatus = () => (<TransmissionNetworkStatus />);

  render = () => {
    const { authState } = this.props;

    return (
      <ErrorBoundary>
        {authState === 'authenticated' && this.renderLayout()}
      </ErrorBoundary>
    );
  }
}

App.propTypes = {
  authState: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({}),
};
App.defaultProps = {
  i18n: null,
};
App.displayName = 'App';

// Workaround for using Enzyme's shallow rendering with context API
// TODO: Remove when context support is added
if (process.env.NODE_ENV === 'test') {
  App.contextTypes = {
    currentUser: PropTypes.shape({}),
    clearCurrentUser: PropTypes.func,
    getSelectedMarketParty: PropTypes.func,
    handleDbChange: PropTypes.func,
    initialize: PropTypes.func,
    selectMarketParty: PropTypes.func,
    selectedMarketPartyId: PropTypes.string,
    isProduction: PropTypes.bool,
    isStaging: PropTypes.bool,
    isDevelopment: PropTypes.bool,
    isAdmin: PropTypes.func,
    isReadOnlyAdmin: PropTypes.func,
    isAdminAsMarketParty: PropTypes.func,
    isReadOnlyAdminAsMarketParty: PropTypes.func,
  };
} else {
  App.contextType = Context;
}

const AppTranslated = withTranslation()(App);

export default withRouter(AppTranslated);
export { App as PureComponent };
